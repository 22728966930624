.block-content {
    padding: 10px 20px;
    margin-bottom: 20px;
    background: color("grey", "base");
    border: 2px solid color("grey", "light");
    border-radius: 5px;

    &--title {
        padding: 20px;
    }

    .h2 {
        margin-bottom: 30px;
    }

    img {
        max-width: 100%;
    }
}
