.field-control {
    &__select {
        display: block;
        width: 100%;
        height: 45px;
        padding: 0 40px 0 20px;
        border-radius: 50px;
        border: 1px solid color("secondary", "base");
        background: url("../../../images/arrow.svg") no-repeat;
        background-position: calc(100% - 10px) center;
        background-size: 11px;
        color: color("text", "base");
        appearance: none;
        outline: none;

        &:focus {
            border: 2px solid color("secondary", "base");
        }

        &::placeholder {
            color: rgba(color("primary", "base"), 0.5);
        }
    }
}
select {
    padding: 15px 40px 15px 20px;
    border-radius: 50px;
    border: 1px solid color("secondary", "base");
    background: url("../../../images/arrow.svg") no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 11px;
    appearance: none;

    &:focus {
        border: 2px solid color("secondary", "base");
    }
}

// Pagination
.field-control__pagination {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.field-control-select__input {
    background: none;
    border: none;
    color: color("white", "base");
    cursor: pointer;
}

.field-control-select__list {
    position: absolute;
    background: color("grey", "base");
    top: calc(100% - 5px);
    left: 0;
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 0;
    border: 2px solid color("grey", "light");
    border-top: 0;
    border-radius: 5px;
}

.field-control-select__item {
    list-style: none;
    padding: 5px;
    padding-left: 20px;
    cursor: pointer;
    background: color("white", "base");

    &:hover {
        color: color("white", "base");
        background: color("primary", "base");
        font-weight: 500;
    }
}
