// Font icons
$icomoon-font-family: "icon-rallye" !default;
$icomoon-font-path: "../../fonts/icon-rallye" !default;

$cache-buster: "umogj9";

@font-face {
    font-family: "#{$icomoon-font-family}";
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$cache-buster}");
    src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?#{$cache-buster}#iefix") format("embedded-opentype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?#{$cache-buster}") format("truetype"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?#{$cache-buster}") format("woff"),
        url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?#{$cache-buster}##{$icomoon-font-family}") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.use-icon,
[class^="icon-"],
[class*=" icon-"] {
    font-family: "#{$icomoon-font-family}" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
$icon-Tick-Square-path1: "\e93f";
$icon-Tick-Square-path2: "\e940";
$icon-User-path1: "\e941";
$icon-User-path2: "\e942";
$icon-User-path3: "\e943";
$icon-User-path4: "\e944";
$icon-User-path5: "\e945";
$icon-User-path6: "\e946";
$icon-Reload-path1: "\e947";
$icon-Reload-path2: "\e948";
$icon-Reload-path3: "\e949";
$icon-Reload-path4: "\e94a";
$icon-Redo-path1: "\e94b";
$icon-Redo-path2: "\e94c";
$icon-Category-path1: "\e900";
$icon-Category-path2: "\e901";
$icon-Send-path1: "\e902";
$icon-Send-path2: "\e903";
$icon-Chat-path1: "\e904";
$icon-Chat-path2: "\e905";
$icon-Location-path1: "\e906";
$icon-Location-path2: "\e907";
$icon-Filter-path1: "\e908";
$icon-Filter-path2: "\e909";
$icon-Filter-path3: "\e90a";
$icon-Filter-path4: "\e90b";
$icon-Hide-path1: "\e90c";
$icon-Hide-path2: "\e90d";
$icon-Unlock-path1: "\e90e";
$icon-Unlock-path2: "\e90f";
$icon-Document-path1: "\e910";
$icon-Document-path2: "\e911";
$icon-Danger-path1: "\e912";
$icon-Danger-path2: "\e913";
$icon-Close-Square-path1: "\e914";
$icon-Close-Square-path2: "\e915";
$icon-Calendar-path1: "\e916";
$icon-Calendar-path2: "\e917";
$icon-Calendar-path3: "\e918";
$icon-Calendar-path4: "\e919";
$icon-Arrow---Down-4: "\e91a";
$icon-Arrow---Down-3-path1: "\e91b";
$icon-Arrow---Down-3-path2: "\e91c";
$icon-Arrow---Up-4: "\e91d";
$icon-Arrow---Up-3-path1: "\e91e";
$icon-Arrow---Up-3-path2: "\e91f";
$icon-Arrow---Right-4: "\e920";
$icon-Arrow---Right-3-path1: "\e921";
$icon-Arrow---Right-3-path2: "\e922";
$icon-Arrow---Left-4: "\e923";
$icon-Arrow---Left-3-path1: "\e924";
$icon-Arrow---Left-3-path2: "\e925";
$icon-Calling-path1: "\e926";
$icon-Calling-path2: "\e927";
$icon-Calling-path3: "\e928";
$icon-Edit-path1: "\e929";
$icon-Edit-path2: "\e92a";
$icon-Edit-path3: "\e92b";
$icon-Notification-path1: "\e92c";
$icon-Notification-path2: "\e92d";
$icon-Lock-path1: "\e92e";
$icon-Lock-path2: "\e92f";
$icon-Profile-path1: "\e930";
$icon-Profile-path2: "\e931";
$icon-Show-path1: "\e932";
$icon-Show-path2: "\e933";
$icon-Setting-path1: "\e934";
$icon-Setting-path2: "\e935";
$icon-Search-path1: "\e936";
$icon-Search-path2: "\e937";
$icon-Home: "\e938";
$icon-Delete-path1: "\e939";
$icon-Delete-path2: "\e93a";
$icon-Login-path1: "\e93b";
$icon-Login-path2: "\e93c";
$icon-Logout-path1: "\e93d";
$icon-Logout-path2: "\e93e";
$icon-Credit-Card: "\e950";

.icon-Tick-Square .path1 {
    &:before {
        content: $icon-Tick-Square-path1;
        color: rgb(203, 211, 230);
    }
}
.icon-Tick-Square .path2 {
    &:before {
        content: $icon-Tick-Square-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-User .path1 {
    &:before {
        content: $icon-User-path1;
        color: rgb(59, 89, 152);
    }
}
.icon-User .path2 {
    &:before {
        content: $icon-User-path2;
        margin-left: -1em;
        color: rgb(203, 211, 230);
    }
}
.icon-User .path3 {
    &:before {
        content: $icon-User-path3;
        margin-left: -1em;
        color: rgb(203, 211, 230);
    }
}
.icon-User .path4 {
    &:before {
        content: $icon-User-path4;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-User .path5 {
    &:before {
        content: $icon-User-path5;
        margin-left: -1em;
        color: rgb(203, 211, 230);
    }
}
.icon-User .path6 {
    &:before {
        content: $icon-User-path6;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Reload .path1 {
    &:before {
        content: $icon-Reload-path1;
        color: rgb(203, 211, 230);
    }
}
.icon-Reload .path2 {
    &:before {
        content: $icon-Reload-path2;
        margin-left: -1em;
        color: rgb(203, 211, 230);
    }
}
.icon-Reload .path3 {
    &:before {
        content: $icon-Reload-path3;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Reload .path4 {
    &:before {
        content: $icon-Reload-path4;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Redo .path1 {
    &:before {
        content: $icon-Redo-path1;
        color: rgb(203, 211, 230);
    }
}
.icon-Redo .path2 {
    &:before {
        content: $icon-Redo-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Category .path1 {
    &:before {
        content: $icon-Category-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Category .path2 {
    &:before {
        content: $icon-Category-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Send .path1 {
    &:before {
        content: $icon-Send-path1;
        color: rgb(59, 89, 152);
    }
}
.icon-Send .path2 {
    &:before {
        content: $icon-Send-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Chat .path1 {
    &:before {
        content: $icon-Chat-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Chat .path2 {
    &:before {
        content: $icon-Chat-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Location .path1 {
    &:before {
        content: $icon-Location-path1;
        color: rgb(59, 89, 152);
    }
}
.icon-Location .path2 {
    &:before {
        content: $icon-Location-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Filter .path1 {
    &:before {
        content: $icon-Filter-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Filter .path2 {
    &:before {
        content: $icon-Filter-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Filter .path3 {
    &:before {
        content: $icon-Filter-path3;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Filter .path4 {
    &:before {
        content: $icon-Filter-path4;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Hide .path1 {
    &:before {
        content: $icon-Hide-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Hide .path2 {
    &:before {
        content: $icon-Hide-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Unlock .path1 {
    &:before {
        content: $icon-Unlock-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Unlock .path2 {
    &:before {
        content: $icon-Unlock-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Document .path1 {
    &:before {
        content: $icon-Document-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Document .path2 {
    &:before {
        content: $icon-Document-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Danger .path1 {
    &:before {
        content: $icon-Danger-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Danger .path2 {
    &:before {
        content: $icon-Danger-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Close-Square .path1 {
    &:before {
        content: $icon-Close-Square-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Close-Square .path2 {
    &:before {
        content: $icon-Close-Square-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Calendar .path1 {
    &:before {
        content: $icon-Calendar-path1;
        color: rgb(59, 89, 152);
    }
}
.icon-Calendar .path2 {
    &:before {
        content: $icon-Calendar-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Calendar .path3 {
    &:before {
        content: $icon-Calendar-path3;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Calendar .path4 {
    &:before {
        content: $icon-Calendar-path4;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Arrow---Down-4 {
    &:before {
        content: $icon-Arrow---Down-4;
        color: #3b5998;
    }
}
.icon-Arrow---Down-3 .path1 {
    &:before {
        content: $icon-Arrow---Down-3-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Arrow---Down-3 .path2 {
    &:before {
        content: $icon-Arrow---Down-3-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Arrow---Up-4 {
    &:before {
        content: $icon-Arrow---Up-4;
        color: #3b5998;
    }
}
.icon-Arrow---Up-3 .path1 {
    &:before {
        content: $icon-Arrow---Up-3-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Arrow---Up-3 .path2 {
    &:before {
        content: $icon-Arrow---Up-3-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Arrow---Right-4 {
    &:before {
        content: $icon-Arrow---Right-4;
        color: #3b5998;
    }
}
.icon-Arrow---Right-3 .path1 {
    &:before {
        content: $icon-Arrow---Right-3-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Arrow---Right-3 .path2 {
    &:before {
        content: $icon-Arrow---Right-3-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Arrow---Left-4 {
    &:before {
        content: $icon-Arrow---Left-4;
        color: #3b5998;
    }
}
.icon-Arrow---Left-3 .path1 {
    &:before {
        content: $icon-Arrow---Left-3-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Arrow---Left-3 .path2 {
    &:before {
        content: $icon-Arrow---Left-3-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Calling .path1 {
    &:before {
        content: $icon-Calling-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Calling .path2 {
    &:before {
        content: $icon-Calling-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Calling .path3 {
    &:before {
        content: $icon-Calling-path3;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Edit .path1 {
    &:before {
        content: $icon-Edit-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Edit .path2 {
    &:before {
        content: $icon-Edit-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Edit .path3 {
    &:before {
        content: $icon-Edit-path3;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Notification .path1 {
    &:before {
        content: $icon-Notification-path1;
        color: rgb(59, 89, 152);
    }
}
.icon-Notification .path2 {
    &:before {
        content: $icon-Notification-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Lock .path1 {
    &:before {
        content: $icon-Lock-path1;
        color: rgb(59, 89, 152);
    }
}
.icon-Lock .path2 {
    &:before {
        content: $icon-Lock-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Profile .path1 {
    &:before {
        content: $icon-Profile-path1;
        color: rgb(59, 89, 152);
    }
}
.icon-Profile .path2 {
    &:before {
        content: $icon-Profile-path2;
        margin-left: -1em;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Show .path1 {
    &:before {
        content: $icon-Show-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Show .path2 {
    &:before {
        content: $icon-Show-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Setting .path1 {
    &:before {
        content: $icon-Setting-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Setting .path2 {
    &:before {
        content: $icon-Setting-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Search .path1 {
    &:before {
        content: $icon-Search-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Search .path2 {
    &:before {
        content: $icon-Search-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Home {
    &:before {
        content: $icon-Home;
        color: #3b5998;
    }
}
.icon-Delete .path1 {
    &:before {
        content: $icon-Delete-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Delete .path2 {
    &:before {
        content: $icon-Delete-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Login .path1 {
    &:before {
        content: $icon-Login-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Login .path2 {
    &:before {
        content: $icon-Login-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}
.icon-Logout .path1 {
    &:before {
        content: $icon-Logout-path1;
        color: rgb(125, 144, 193);
        opacity: 0.4;
    }
}
.icon-Logout .path2 {
    &:before {
        content: $icon-Logout-path2;
        margin-left: -1em;
        color: rgb(59, 89, 152);
    }
}

.icon-Credit-Card {
    &:before {
        content: $icon-Credit-Card;
        color: rgb(59, 89, 152);
    }
}

.color-icon {
    color: rgb(59, 89, 152);
}
