.list-wrapper {
    background: $white;
    padding: 53px 60px;
    border-radius: 20px;
    margin-bottom: 15px;

    &__header {
        margin-bottom: 20px;
        margin-top: 10px;

        @include media-breakpoint-up(medium) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__header-button {
        margin-left: auto;
        @include media-breakpoint-down(small) {
            text-align: right;
            margin-top: 20px;
        }

        .btn {
            margin-left: 20px;
        }
    }

    &__button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;

        @include media-breakpoint-up(medium) {
            width: auto;
            margin-top: 0;
            justify-content: flex-end;
        }
    }

    &__filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-up(medium) {
            flex-direction: row;
        }
    }

    &__fields {
        width: 100%;
        grid-gap: 15px;

        @include media-breakpoint-up(medium) {
            width: auto;
            grid-gap: 25px;
        }
    }

    &__btn-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        padding: 0 10px;
        font-size: 20px;

        &:hover,
        &:focus {
            background: transparent;

            .tooltip {
                z-index: 2;
                top: calc(100% + 10px);
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: auto;
                height: auto;
                padding: 5px 8px;
                clip: auto;
                border-radius: 5px;
                overflow: visible;
                font-size: 14px;
                white-space: nowrap;
                background: color("primary", "base");
                color: $white;

                @include media-breakpoint-up(medium) {
                    left: auto;
                    right: 0;
                    transform: none;
                }
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse;

        @include media-breakpoint-up(medium) {
            flex-direction: row;
        }
    }

    &__pagination {
        @include media-breakpoint-down(small) {
            margin-top: 20px;
        }
    }
}
