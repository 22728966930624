.block-infos {
    margin-bottom: 20px;
    padding: 25px 60px;
    border-radius: 20px;
    background: url("../../../images/bg-infos.svg") no-repeat,
        linear-gradient(90deg, color("primary", "base") 42%, rgba(color("danger", "base"), 0.3) 94%);
    background-position: right center;
    background-size: contain;

    &__title {
        margin-bottom: 13px;
        color: $white;
    }

    &__text {
        color: $white;
    }
}
