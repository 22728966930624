.subscription {
    &__header {
        margin-bottom: 20px;
    }

    &__description {
        margin-top: 12px;
        color: color("primary", "light");
    }

    &__content {
        padding: 40px 60px 50px;
        margin-bottom: 20px;
        border-radius: 20px;
        background: $white;

        .tabs__tab {
            position: relative;
            margin-right: 60px;

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &::after {
                content: "\e920";
                position: absolute;
                top: 50%;
                right: -30px;
                transform: translate(50%, -50%);
                display: inline-block;
                font-family: "icon-rallye";
                font-size: 15px;
                color: color("secondary", "medium");
            }
        }
    }

    &__number-step {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin-right: 10px;
        background: color("primary", "base");
        color: $white;
        font-weight: 600;
        transition: background 0.5s ease;
    }

    &__fieldset-grid {
        border: none;

        @include media-breakpoint-up(medium) {
            padding-right: 100px;
        }

        @include media-breakpoint-up(large) {
            padding-right: 150px;
        }

        &.subscription__fieldset-grid {
            margin-top: 50px;
        }
    }

    &__legend {
        margin-bottom: 30px;
    }

    &__button {
        display: block;
        width: 100%;
        padding-right: 30px;
        margin-top: 40px;
        text-align: right;
    }

    &__grid {
        grid-gap: 25px;

        & + .subscription__grid {
            margin-top: 25px;

            @include media-breakpoint-up(medium) {
                margin-top: 35px;
            }
        }

        @include media-breakpoint-up(medium) {
            grid-gap: 35px;
        }
    }

    &__group-fields {
        position: relative;
        padding: 24px;
        border: 1px solid color("secondary", "light");
        border-radius: 20px;
        margin-bottom: 25px;

        &:nth-child(even) {
            background: color("secondary", "light");
        }
    }

    &__delete-fields {
        position: absolute;
        top: 0;
        right: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);
        padding: 0;
        background: $white;
        border: 1px solid color("secondary", "light");
        border-radius: 50%;
        font-size: 20px;

        &:hover,
        &:focus {
            border-color: color("secondary", "light");
            background: color("secondary", "light");
            .tooltip {
                z-index: 2;
                top: calc(100% + 10px);
                right: 0;
                display: block;
                width: auto;
                height: auto;
                padding: 5px 8px;
                clip: auto;
                border-radius: 5px;
                overflow: visible;
                font-size: 14px;
                white-space: nowrap;
                background: color("primary", "base");
                color: $white;
            }
        }
    }

    &__end-title {
        position: relative;
        padding-bottom: 26px;
        margin-bottom: 32px;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -60px;
            width: calc(100% + 120px);
            height: 2px;
            background: rgba(203, 211, 230, 0.5);
        }
    }

    &__end-content {
        text-align: center;
        margin-bottom: 20px;
    }

    &__end-icon {
        font-size: 40px;
    }

    &__end-text {
        margin-top: 32px;
        font-weight: 500;
        color: color("primary", "base");
    }
}
