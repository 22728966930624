.intervaltext {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: center;

    &-separateur {
        font-weight: bold;
        font-size: 2.8rem;
        margin: 7px 10px;

        &::before {
            content: "/";
        }
    }

    .field {
        flex: 1;
    }
}
