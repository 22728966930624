%h {
    font-family: $font-title;
    font-weight: normal;
    margin: 0;
    line-height: 1.2;
}

%h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
}

%h2 {
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
}

%h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}

%h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;

    @include media-breakpoint-up("medium") {
        font-size: 20px;
    }
}

%h5 {
    font-size: 18px;
    line-height: 1.2;

    @include media-breakpoint-up("medium") {
        font-size: 20px;
    }
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    @extend %h;
}

.h1,
h1 {
    @extend %h1;
}

.h2,
h2 {
    @extend %h2;
}

.h3,
h3 {
    @extend %h3;

    &--regular {
        font-weight: 400;
    }
}

.h4,
h4 {
    @extend %h4;

    &--regular {
        font-weight: 400;
    }
}

.h5,
h5 {
    @extend %h5;
}
