.pagination {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    .wp-pagenavi {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination-item {
        list-style: none;
        margin-right: 5px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            padding: 0 5px;
            border-radius: 5px;
            background: none;
            border: 1px solid color("secondary", "medium");
            transition: background 0.5s ease;
        }

        &--previous,
        &--next {
            button {
                border-color: transparent;
            }
        }

        .icon {
            font-size: 24px;
        }

        &:hover,
        &:focus {
            button {
                background: color("secondary", "medium");
            }
        }

        &--active {
            pointer-events: none;

            button {
                pointer-events: none;
                background: color("secondary", "medium");
            }
        }
    }
}

.crud-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
