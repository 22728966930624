.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    //overflow: hidden;
    padding: $container-padding-mobile;
    max-width: 1440px;

    @include media-breakpoint-up(small) {
        padding: $container-padding-tablett;
    }

    @include media-breakpoint-up(large) {
        padding: $container-padding-desktop;
    }

    &--no-padding {
        padding: 0;
    }
}
