.field {
    &__feedback {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-family: $font-text;
        // white-space: nowrap;
        min-height: 3rem;
        padding: 0.6rem 0 0.8rem 0.8rem;
        visibility: hidden;

        &.error {
            color: color("danger", "base");
            text-align: right;
            font-style: italic;
            font-size: 12px;
            line-height: 14px;
            visibility: visible;

            ul {
                margin: 0;
                padding: 0;
                padding-left: 1em;
            }
        }
    }
}
