.field-control {
    $self: &;
    position: relative;

    &.error {
        #{$self}__input {
            border-color: color("danger", "base");
        }
    }

    &__adornment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &--end {
            right: 14px;

            .icon {
                font-size: 24px;
            }
        }
    }

    &__input-group {
        position: relative;

        .icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__input {
        padding: 0 20px;
        height: 45px;
        width: 100%;
        border: 1px solid color("secondary", "base");
        outline: 1px solid transparent;
        border-radius: 50px;
        background: transparent;
        color: color("text", "base");
        font-size: 14px;
        line-height: 16px;
        box-shadow: none;
        transition: border 0.2s ease;
        outline: none;

        &--small {
            height: 26px;
            padding: 0 15px;
        }

        &:focus {
            border: 2px solid color("secondary", "base");
        }

        &::placeholder {
            font-family: $font-text;
            color: rgba(color("primary", "base"), 0.5);
            font-style: italic;
        }
    }
}
