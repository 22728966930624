a {
    text-decoration: none;
}

.introduction {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;

    @include media-breakpoint-up("medium") {
        font-size: 20px;
    }
}

.comment {
    font-size: 13px;
    line-height: 1.25;
}

ul,
ol {
    padding-left: 20px;
}

blockquote {
    margin: 0;
}

.link-hover {
    transition: color 0.2s ease;

    &:hover {
        color: color("primary", "base");
    }
}
