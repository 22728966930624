.field-control {
    &__textarea {
        $self: &;
        width: 100%;
        height: 100%;
        padding: 15px;
        margin-top: 1em;
        border-radius: 25px;
        background-color: transparent;
        border: 1px solid color("secondary", "base");
        color: color("text", "base");
        resize: none;
        transition: border 0.3s;
        outline: none;

        &:focus {
            border: 2px solid color("secondary", "base");
        }

        &::placeholder {
            color: rgba(color("primary", "base"), 0.5);
        }
    }
}
