.footer {
    padding: 15px 0;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content-left {
        display: flex;
        align-items: center;
    }

    &__content-right {
        display: flex;
        align-items: center;
    }

    &__logo {
        display: block;
        width: auto;
        max-height: 30px;
    }

    &__logo-globalis {
        display: block;
        max-height: 25px;
        width: auto;
    }

    &__text {
        padding: 0 10px;
        color: color("primary", "light");
        font-size: 12px;
        line-height: 14px;
    }
}
