.tabs {
    &__tablist {
        position: relative;
        margin-bottom: 45px;
        display: flex;
        flex-wrap: nowrap;

        @include media-breakpoint-down(small) {
            overflow-x: scroll;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -60px;
            width: calc(100% + 120px);
            height: 2px;
            background: rgba(color("secondary", "medium"), 0.5);
        }
    }

    &__elements {
        background-color: color("white", "base");
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding-left: 10px;
    }

    &__tab {
        position: relative;
        z-index: 2;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        padding: 12px 10px;
        margin-right: 10px;
        background: none;
        border-bottom: 4px solid transparent;
        font-weight: 600;
        transition: border 0.5s ease;

        &.active,
        &:hover,
        &:focus {
            border-color: color("danger", "base");

            .subscription__number-step {
                background: color("danger", "base");
            }
        }
    }
}

#organizer {
    font-weight: 450;
}
