.field {
    &__group {
        margin-bottom: 20px;

        .field + .field {
            margin-top: 20px;
        }
    }

    &__row {
        & + .field__row {
            margin-top: 1em;
        }

        &--inline {
            display: flex;

            > *[class^="field__"] + *[class^="field__"] {
                margin-left: $spacing-unit * 2;
                margin-top: 0;
            }
        }
    }
}
