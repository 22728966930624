@mixin embedContainer($ratio: null) {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;

    @if $ratio != null {
        @include embedRatio($ratio);
    }
}

@mixin embedRatio($ratio) {
    padding-top: calc(#{$ratio} * 1%);
}

@mixin embedPosition($position) {
    @if $position == "center" {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @if $position == "top" {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @if $position == "bottom" {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @if $position == "left" {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    @if $position == "right" {
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    //@else {
    //    top: 50%;
    //    left: $position;
    //    transform: translate(-$position, -50%);
    //}
}

@mixin embedSize($size) {
    @if $size == "cover" {
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        object-fit: cover;
    }

    @if $size == "contain" {
        max-width: 100%;
        height: auto;
    }
}

@mixin embed($position: "center", $size: "cover") {
    position: absolute;
    z-index: 0;

    @if $position != null {
        @include embedPosition($position);
    }
    @if $size != null {
        @include embedSize($size);
    }
}
