.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 44px;
    padding: 0 35px;
    border: 1px solid transparent;
    border-radius: 50px;
    text-align: center;
    color: color("primary", "base");
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
    transition: color 0.4s ease, background 0.4s ease, border 0.4s ease;

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background: color("primary", "medium");
        border-color: color("primary", "medium");
    }

    &--large {
        min-width: 300px;
    }

    &--small {
        padding: 0 32px;
        height: 26px;
    }

    &--default {
        background: color("primary", "base");
        border-color: color("primary", "base");
        color: $white;

        &:hover {
            background: color("primary", "medium");
            border-color: color("primary", "medium");
        }
    }

    &--disabled,
    &:disabled {
        background: color("secondary", "medium");
        border-color: color("secondary", "medium");
        color: $white;

        &:hover {
            background: color("secondary", "medium");
            border-color: color("secondary", "medium");
            cursor: not-allowed;
        }
    }

    &--lg {
        min-width: 200px;
    }

    &--full {
        width: 100%;
    }

    &--secondary {
        background: transparent;
        border-color: color("primary", "base");
        color: color("primary", "base");

        &:hover,
        &:focus {
            background: color("primary", "base");
            color: $white;
        }
    }

    &--white {
        background: color("white", "base");
        color: color("primary", "base");

        &:hover {
            background: color("primary", "base");
            color: color("white", "base");
        }
    }

    &__icon {
        @include media-breakpoint-up("medium") {
            margin-right: 10px;
        }
    }
    &--icon:hover span:before {
        color: white !important;
    }
}
