.field-control {
    &__radio {
        $self: &;
        position: relative;

        &--inline {
            display: flex;
            width: auto;
            flex-wrap: wrap;

            > .field-control__radio-group {
                margin-left: $spacing-unit;
                margin-top: 0;
            }
        }

        &--disabled {
            #{$self}-label {
                cursor: not-allowed;
            }
        }

        & + #{$self} {
            margin-top: 1em;
        }

        &-container {
            width: 100%;
            margin: -1rem 0;

            &--column {
                display: flex;
                flex-direction: column;

                #{$self} + #{$self} {
                    margin-bottom: 0.8rem;
                }
            }
        }

        &-label {
            position: relative;
            padding-left: 28px;
            margin-top: 4px;
            display: inline-flex;

            &:before {
                content: "";
                position: absolute;
                display: block;
                top: 2px;
                left: 2px;
                width: 16px;
                height: 16px;
                background-color: $white;
                border: 1px color("grey", "dark") solid;
                border-radius: 50%;
            }

            &:after {
                content: "";
                position: absolute;
                top: 2px;
                left: 2px;
                width: 14px;
                height: 14px;
                display: block;
                border: 1px solid transparent;
                background-color: transparent;
                box-sizing: content-box;
                border-radius: 50%;
                outline: 2px solid #fff;
                outline-offset: -3px;
            }

            sup {
                color: color("danger", "base");
                top: 0.5rem;
                left: 0.3rem;
                position: relative;
            }
        }

        &-input {
            width: 100%;
            cursor: inherit;
            margin: 0;
            opacity: 0;
            padding: 0;
            z-index: 1;
            position: absolute;

            &:checked {
                ~ #{$self}-label:after {
                    background-color: color("secondary", "base");
                    border-color: color("grey", "dark");
                    background-position: center;
                    background-size: contain;
                }

                ~ #{$self}-label:before {
                    border-color: transparent;
                }
            }

            &:focus {
                ~ #{$self}-label:before {
                    outline: 2px solid color("primary", "base");
                }
            }

            &:disabled,
            &.disabled {
                ~ #{$self}-label:after {
                    background-color: transparent;
                }

                ~ #{$self}-label:before {
                    border-color: color("secondary", "base");
                    background-color: color("secondary", "base");
                }

                &:checked {
                    ~ #{$self}-label:after {
                        background-color: color("secondary", "base");
                        border-color: color("secondary", "base");
                    }
                }
            }
        }
    }
}
