.hidden {
    display: none !important;
    @each $breakpoint, $_ in $breakpoints {
        @include media-breakpoint-down($breakpoint) {
            &--down-#{$breakpoint} {
                display: none !important;
            }
        }
        @include media-breakpoint-up($breakpoint) {
            &--up-#{$breakpoint} {
                display: none !important;
            }
        }
    }
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
