.block-rallye-visual {
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }

    &__selected {
        outline: 2px solid black;
    }
}
