.main {
    padding: 30px 0;
    min-height: calc(100vh - 145px);
}

.grid {
    display: flex;
    justify-content: space-between;
    clear: both;
}
.grid + .grid {
    margin-top: 15px;
}
.grid.align-baseline {
    align-items: baseline;
}
.grid.align-end {
    align-items: flex-end;
}
.grid.align-middle {
    align-items: center;
}
.grid > * {
    width: 100%;
    //margin-left: 15px; alignement avec des ligne complete
    padding: 20px 30px;
    border-radius: 20px;
    overflow: hidden;
}
.grid > *.no-padding {
    padding: 0;
}
.grid > *.no-radius {
    border-radius: 0;
}
.grid > *.align-baseline {
    align-self: baseline;
}
.grid > *.align-end {
    align-self: flex-end;
}
.grid > *.align-middle {
    align-self: center;
}
.grid > *:first-child {
    margin-left: 0;
}
.grid .grid-1 {
    flex: 1;
}
.grid .grid-2 {
    flex: 2;
}
.grid .grid-3 {
    flex: 3;
}
.grid .grid-4 {
    flex: 4;
}
.grid .grid-5 {
    flex: 5;
}
.grid .grid-6 {
    flex: 6;
}
.grid .grid-7 {
    flex: 7;
}
.grid .grid-8 {
    flex: 8;
}
.grid .grid-9 {
    flex: 9;
}
.grid .grid-10 {
    flex: 10;
}
.grid .grid-11 {
    flex: 11;
}
.grid .grid-12 {
    flex: 12;
}

.grid-auto-flow {
    grid-auto-flow: column;
}
