.bold {
    font-weight: 700 !important;
}

.bolder {
    font-weight: 600 !important;
}

.italic {
    font-style: italic !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.no-wrap {
    white-space: nowrap;
}

.pre-line {
    white-space: pre-line;
}

.text-white {
    color: $white !important;
}

.textleft {
    text-align: left !important;
}

.textright {
    text-align: right !important;
}

.font-size--18 {
    font-size: 18px;
}
