.header-login {
    @include embedContainer(17);
    min-height: 255px;
    background: $white;

    &__img {
        @include embed("center", "cover");
    }
}

.form-login {
    margin: 40px 0 100px;
    padding: 55px 60px;
    border-radius: 20px;
    background: $white;

    .h1 {
        margin-bottom: 10px;
    }

    &__description {
        margin-bottom: 30px;
        color: color("primary", "light");
    }

    &__button {
        display: block;
        margin-top: 10px;
        width: 100%;

        &--first {
            margin-top: 45px;
        }

        .btn {
            width: 100%;
        }
    }
}
