.topbar {
    position: relative;
    background: $white;
    z-index: 5;

    &--connected {
        .topbar__content {
            justify-content: space-between;
        }
    }

    @include media-breakpoint-down(small) {
        &--open {
            .topbar__content {
                display: block;
            }

            .topbar__btn-bar {
                &:nth-child(1) {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: rotate(-45deg);
                }

                &:last-child {
                    display: none;
                }
            }
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding: 13px 55px;
    }

    &__logo {
        max-height: 40px;
        width: auto;
        margin-right: 50px;

        img {
            width: auto;
            height: 100%;
        }

        @include media-breakpoint-up(medium) {
            max-height: 57px;
        }
    }

    &__text {
        color: $black;
    }

    &__content {
        display: none;

        @include media-breakpoint-down(small) {
            position: absolute;
            z-index: 10;
            top: 100%;
            left: 0;
            width: 100%;
            height: calc(100vh - 65px);
            background: $white;
        }

        @include media-breakpoint-up(medium) {
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &__right {
        text-align: center;

        @include media-breakpoint-down(small) {
            .flex {
                justify-content: center;
            }
        }

        @include media-breakpoint-up(medium) {
            text-align: right;
        }
    }

    &__nav {
        position: relative;

        @include media-breakpoint-up(medium) {
            height: 100%;
        }
    }

    &__btn {
        position: relative;
        top: -2px;
        background: none !important;
        padding: 10px;
        padding-top: 0;
        font-size: 25px;

        &:hover,
        &:focus {
            .tooltip {
                z-index: 2;
                top: calc(100% + 10px);
                right: 0;
                display: block;
                width: auto;
                height: auto;
                padding: 5px 8px;
                clip: auto;
                border-radius: 5px;
                overflow: visible;
                font-size: 14px;
                white-space: nowrap;
                background: color("primary", "base");
                color: $white;
            }
        }
    }

    &__btn-menu {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 40px;
        height: 40px;
        background: none !important;
        border: none;

        @include media-breakpoint-up(medium) {
            display: none;
        }
    }

    &__btn-bar {
        display: block;
        width: 100%;
        padding: 0;
        height: 3px;
        border-radius: 5px;
        background: color("primary", "base");
    }
}

.topbar-menu {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(medium) {
        transform: translateY(13px);
    }

    @include media-breakpoint-down(small) {
        flex-direction: column;
    }

    &__item {
        position: relative;
        list-style: none;
        padding: 10px 30px;
        margin: 0;
        text-align: center;

        @include media-breakpoint-up(medium) {
            height: 100%;
            padding: 0 30px;
        }
    }

    &__link {
        display: block;
        height: 100%;
        padding-top: 10px;
        font-weight: 600;
        border-bottom: 5px solid transparent;
        transition: border 0.5s ease;

        &:focus,
        &:hover,
        &.active {
            border-bottom: 5px solid color("warning", "base");
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__user {
        .topbar-menu__item {
            padding-right: 0;
        }
    }
}

.topbar-submenu {
    display: none;
    list-style: none;
    background-color: color("white", "base");

    &--open {
        z-index: 25;
        position: absolute;
        top: 65px;
        left: 0;
        right: 0;
        min-width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        border-radius: 10px;

        @include media-breakpoint-down(small) {
            position: initial;
            align-items: flex-start;
            min-width: initial;
        }
    }

    &__item {
        display: flex;
        padding: 5px 0;

        @include media-breakpoint-down(small) {
            padding: 5px 0;
        }

        &:hover {
            border-bottom: 2px solid color("primary", "base");
        }
    }
}
