.popup {
    &__content {
        padding: 40px 45px;
        background: $white;

        @include media-breakpoint-up("medium") {
            min-width: 900px;
        }

        .popup__close {
            background: none;
            border: 0;
            box-shadow: none;
            cursor: pointer;
        }
    }
}
