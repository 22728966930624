@import "./color";
@import "./hidden";
@import "./spacing";
@import "./font";
@import "./flex";

.position-relative {
    position: relative;
}

.position-sticky {
    @include media-breakpoint-up("medium") {
        position: sticky;
        top: 170px;
    }
}
