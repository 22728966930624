.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(color("background", "base"), 0.8);
    z-index: 100;

    &__block {
        position: absolute;
        z-index: 101;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0vw;
        max-height: 95vh;
        background: $white;
        border-radius: 20px;
        overflow-x: auto;

        @include media-breakpoint-up("small") {
            width: 50vw;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: color("primary", "base");
        padding: 20px;
        color: $white;
    }

    &__btn-close {
        text-align: center;
        font-size: 24px;
        background: transparent;
        color: $white;
    }

    &__content {
        padding: 20px;

        &--message {
            text-align: center;
        }
    }

    &__action {
        margin-top: 20px;
        text-align: right;

        &--btn {
            margin-left: 10px;
        }
    }
}
