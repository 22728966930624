$primary: (
    "base": #3b5998,
    "light": #344669,
    "medium": #2f4678,
    "dark": #1d2537,
) !default;

$secondary: (
    "base": #7d90c1,
    "light": #eff1f8,
    "medium": #cbd3e6,
) !default;

$tertiary: (
    "base": #b994af,
) !default;

$background: (
    "base": #eff1f8,
) !default;

$text: (
    "base": #1d2537,
) !default;

$white: (
    "base": #ffffff,
) !default;

$grey: (
    "base": #222429,
    "dark": #1b1a20,
    "light": #333242,
) !default;

// Semantic colors
$success: (
    "base": #92c36c,
) !default;

$warning: (
    "base": #f68892,
) !default;

$danger: (
    "base": #fa5969,
) !default;

$danger-bg: (
    "base": #f8d7da,
) !default;

$danger-border: (
    "base": #f5c6cb,
) !default;

$success-bg: (
    "base": #d4edda,
) !default;

$success-border: (
    "base": #c3e6cb,
) !default;

$warning-bg: (
    "base": #fff3cd,
) !default;

$warning-border: (
    "base": #ffeeba,
) !default;

$transparent: (
    "base": #ffffff,
) !default;

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "background": $background,
    "grey": $grey,
    "text": $text,
    "white": $white,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
    "transparent": $transparent,
    "danger-bg": $danger-bg,
    "danger-border": $danger-border,

    "warning-bg": $warning-bg,
    "warning-border": $warning-border,

    "success-bg": $success-bg,
    "success-border": $success-border,
) !default;

$black: #000000;
$white: #ffffff;
