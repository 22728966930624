// Font principal

@font-face {
    font-family: "Merriweather";
    src: url("../../fonts/Merriweather/Merriweather-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Merriweather";
    src: url("../../fonts/Merriweather/Merriweather-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../fonts/Work_Sans/WorkSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../fonts/Work_Sans/WorkSans-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../fonts/Work_Sans/WorkSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../fonts/Work_Sans/WorkSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Work Sans";
    src: url("../../fonts/Work_Sans/WorkSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
