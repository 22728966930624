::selection {
    background: color("primary", "base");
    background: rgba(color("primary", "base"), 0.3);
}

::-moz-selection {
    background: color("primary", "base");
    background: rgba(color("primary", "base"), 0.3);
}

* {
    -webkit-tap-highlight-color: rgba(color("primary", "base"), 0.3);
}
