.dropdown {
    margin-top: 10px;

    &--open {
        .dropdown__icon {
            &.icon-plus {
                display: none;
            }
            &.icon-min {
                display: block;
            }
        }

        .dropdown__content {
            display: block;
        }
    }

    @include media-breakpoint-up("medium") {
        margin-top: 20px;
    }

    &__element {
        padding: 20px 0;
        border-bottom: 1px solid color("grey", "dark");

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }

    &__header {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 25px 70px 25px 25px;
        background: $white;
        cursor: pointer;

        .icon {
            margin-right: 20px;
            font-size: 25px;
        }

        @include media-breakpoint-up("medium") {
            align-items: center;
            padding: 30px 70px 30px 30px;
        }
    }

    &__icon {
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 23px;

        &.icon-min {
            display: none;
        }

        @include media-breakpoint-up("medium") {
            top: 30px;
            font-size: 27px;
        }
    }

    &__content {
        display: none;
        padding: 0 25px 25px;
        background: $white;

        @include media-breakpoint-up("medium") {
            padding: 0 30px 30px;
        }
    }
}

.bg--white {
    .dropdown {
        &__header {
            background: color("grey", "base");
        }

        &__content {
            background: color("grey", "base");
        }
    }
}
