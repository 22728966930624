.fieldset {
    padding: 25px;
    margin-bottom: 25px;
    background: $white;
    border-radius: 20px;

    @include media-breakpoint-up("medium") {
        padding: 55px 60px;
        margin-bottom: 30px;
    }
}
