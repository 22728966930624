@mixin inGrid($element: &) {
    @at-root {
        [class*=" grid--"] #{$element},
        [class^="grid--"] #{$element},
        .grid #{$element} {
            @content;
        }
    }
}

[class*=" grid--"],
[class^="grid--"] {
    display: grid;
    grid-auto-flow: dense;
}

.autogrid,
.grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    // grid-template-columns: repeat(#{$grid-number-cols}, 1fr);
}

@for $i from 1 through $grid-number-cols {
    .grid--#{$i} {
        grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
}

@for $i from 1 through $grid-number-cols {
    .grid__col--#{$i} {
        grid-column: auto / span #{$i};
    }

    .grid__row--#{$i} {
        grid-row: auto / span #{$i};
    }
}

.grid--has-gutter {
    grid-gap: $spacing-unit;

    &-2x {
        grid-gap: $spacing-unit * 2;
    }

    &-3x {
        grid-gap: $spacing-unit * 3;
    }

    &-4x {
        grid-gap: $spacing-unit * 2;

        @include media-breakpoint-up("medium") {
            grid-gap: $spacing-unit * 4;
        }
    }

    &-5x {
        grid-gap: $spacing-unit * 2;

        @include media-breakpoint-up("medium") {
            grid-gap: $spacing-unit * 5;
        }
    }

    &-6x {
        grid-gap: $spacing-unit * 2;

        @include media-breakpoint-up("medium") {
            grid-gap: $spacing-unit * 6;
        }
    }

    @include media-breakpoint-up("medium") {
        grid-column-gap: 2rem;
    }
}

.grid__item--first {
    order: -1;
}

.grid__item--last {
    order: 1;
}

.grid__offset {
    visibility: hidden;
}

.grid__col--all {
    grid-column: 1 / -1;
}

.grid__row--all {
    grid-row: 1 / -1;
}

@each $breakpoint, $_ in mapReverse($breakpoints) {
    $next: breakpoint-next($breakpoint, $breakpoints);
    @if ($next) {
        .grid__item--#{$next}-first {
            order: -1;
        }

        .grid__item--#{$next}-last {
            order: 1;
        }

        .grid__offset--#{$next} {
            visibility: hidden;
        }

        @include media-breakpoint-down($breakpoint) {
            @for $i from 1 through $grid-number-cols {
                .grid--#{$next}-#{$i} {
                    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
                }
                .grid__col--#{$next}-#{$i} {
                    grid-column: auto / span #{$i};
                }
                .grid__row--#{$next}-#{$i} {
                    grid-row: auto / span #{$i};
                }
            }
        }
    }
}
