.filters {
    &__top {
        @include media-breakpoint-up("small") {
            display: flex;
            align-items: flex-end;
        }

        .field__group {
            width: 100%;
        }

        .btn {
            flex: 1 0 auto;
            margin-bottom: 20px;

            &__search {
                @include media-breakpoint-up(small) {
                    position: relative;
                    margin-left: -5px;
                }
            }

            & + .btn {
                margin-left: 15px;
            }
        }
    }

    &__toolbar {
        text-align: right;

        .btn {
            & + .btn {
                margin-left: 15px;
            }
        }
    }
}
