.block {
    padding: 35px 60px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: $white;

    &__title {
        margin-bottom: 20px;
        color: $black;
    }

    &__grid {
        margin: 0;
        padding: 0;
        grid-gap: 25px;

        @include media-breakpoint-up(medium) {
            grid-gap: 50px;
        }
    }

    &__item {
        display: flex;
        align-items: flex-start;
        list-style: none;
    }

    &__color {
        flex: 1 0 auto;
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 10px;
        margin-right: 10px;
    }

    &__text {
        color: color("primary", "light");
    }
}
