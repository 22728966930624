.block-list-rallye {
    position: relative;
    padding: 35px 0 15px 60px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: $white;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 90px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%);
    }

    &__title {
        margin-bottom: 20px;
        color: $black;
    }

    &__element {
        flex: 1 0 auto;
        display: inline-block;
        width: 100px;
        height: 100px;
        list-style: none;
        padding: 15px 20px;
        margin-right: 35px;
        border-radius: 10px;

        &:nth-child(3n + 1) {
            background: color("primary", "base");
        }

        &:nth-child(3n + 2) {
            background: color("secondary", "base");
        }

        &:nth-child(3n + 3) {
            background: color("tertiary", "base");
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin: 0;
        padding: 0 0 20px;
    }

    &__item {
        flex: 0 0 auto;
        width: 178px;
        height: 178px;
        list-style: none;
        padding: 15px 20px;
        margin-right: 35px;
        border-radius: 10px;

        &:nth-child(3n + 1) {
            background: color("primary", "base");
        }

        &:nth-child(3n + 2) {
            background: color("secondary", "base");
        }

        &:nth-child(3n + 3) {
            background: color("tertiary", "base");
        }
    }

    &__header {
        display: flex;
        margin-bottom: 25px;
        &-homepage {
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    &__item-title {
        margin-bottom: 0;
        color: $white;
    }

    &__img {
        display: block;
        max-width: 75px;
        max-height: 60px;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    &__text {
        margin-bottom: 5px;
        color: $white;
    }

    &__year {
        color: $white;
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
    }
}
