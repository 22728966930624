.breadcrumb-list {
    display: block;
    margin: 0;
    padding: 0;

    .breadcrumb-list__item {
        padding: 0 5px 0 0;
        display: inline-block;
        list-style: none;
    }

    .breadcrumb-element {
        color: $white;

        &__separator {
            display: inline-block;
            padding-left: 5px;
        }

        &--link:hover,
        &--link:focus {
            text-decoration: underline;
        }

        &--active {
            font-weight: 700;
            color: color("secondary", "base");
        }
    }
}
