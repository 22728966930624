.flash-message {
    display: flex;
    position: absolute;
    align-items: flex-start;
    width: 100%;
    min-width: 50px;
    height: auto;
    padding: 15px 20px;
    font-size: 16px;
    z-index: 1500;
    right: 0;

    &-container {
        display: block;
        position: fixed;
        z-index: 1500;
        top: 100px;
        right: 0;
        width: 100%;

        & > *:not(:first-child) {
            margin-top: 10px;
        }

        @include media-breakpoint-up("medium") {
            top: 100px;
            right: 5%;
            width: 40%;
            padding-top: 10px;
        }
    }

    &__title {
        font-weight: bold;
        // color: color("gris_eres");
        margin-top: 5px;
    }

    &__content {
        // color: color("gris_eres");
        flex-grow: 1;
        margin-top: 5px;
    }

    &__icon + &__content,
    &__icon + &__title {
        margin-left: 20px;
    }

    &__title + &__content {
        margin-left: 5px;
    }

    &__remove-btn {
        background-color: transparent;
        margin-top: 4px;

        border: none;

        &:before {
            content: "X";
            width: 20px;
            height: 20px;
            cursor: pointer;
            font-weight: bold;
        }
    }

    &--error {
        color: color("danger", "base");
        background-color: color("danger-bg", "base");
        border: 1px solid color("danger-border", "base");
    }

    &--info {
        color: color("secondary", "base");
        background-color: color("warning-bg", "base");
        border: 1px solid color("warning-border", "base");
    }

    &--success {
        color: color("success", "base");
        background-color: color("success-bg", "base");
        border: 1px solid color("success-border", "base");
    }

    &--warning {
        color: color("warning", "base");
        background-color: color("warning-bg", "base");
        border: 1px solid color("warning-border", "base");
    }

    &--show {
        opacity: 1;
    }

    &--hide {
        opacity: 0;
    }
}
