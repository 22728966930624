@import "./hx";
@import "./main";
@import "./table";
@import "./text";

html,
body {
    width: 100%;
    height: 100%;
}
