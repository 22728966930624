.bg--white {
    background: $white;
}

@each $category, $_ in $colors {
    @each $colorName, $color in $_ {
        .bg {
            &--#{$category}-#{$colorName} {
                background-color: $color !important;
            }
        }

        .text-color-on {
            &--#{$category}-#{$colorName} {
                color: text-color($color) !important;
            }
        }
    }
}

.text-color {
    @each $colorName, $_ in $colors {
        &--#{$colorName} {
            @if map-has-key($_, "base") {
                color: color($colorName, "base") !important;
            }
        }
    }

    @each $category, $_ in $colors {
        @each $colorName, $color in $_ {
            &--#{$category}-#{$colorName} {
                color: $color !important;
            }
        }
    }
}
