.autocomplete {
    position: relative;

    .field-control__autocomplete {
        display: flex;

        input {
            flex-grow: 1;
        }

        svg {
            flex-grow: 0;
            margin: auto 10px;
            cursor: pointer;
        }
    }

    .select-list {
        z-index: 100;
        width: 100%;
        position: absolute;
        border: 1px solid color("secondary", "base");
        padding: 0;

        list-style: none;
        margin: 0;

        max-height: 160px;
        overflow-y: auto;
    }

    &__overflow-visible {
        overflow: visible;
    }
}
