.form-error {
    margin-bottom: 1em;

    &__icon {
        display: inline-block;
        vertical-align: bottom;
        padding-top: 2px;
        font-size: 20px;
        color: color("danger", "base");
    }

    .form-error__content {
        display: flex;
        align-items: flex-end;
        font-style: italic;
        font-size: 12px;
        line-height: 14px;
    }

    &__content {
        display: none;
        padding: 0.5em 0;
        font-weight: 700;
        color: color("danger", "base");
    }

    &__list {
        margin-left: 40px;
        margin-top: 0;
        font-weight: 700;
        color: color("danger", "base");
    }

    .field__input,
    .field__select {
        border: 1px solid color("danger", "base");
    }

    //.field__label {
    //    color: color("danger", "base");
    //}

    .field__checkbox-label:before,
    .field__radio-label:before,
    .field__textarea {
        border: 1px solid color("danger", "base");
    }
}

.form-success {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
    font-weight: 700;
    color: color("primary", "base");

    &__icon {
        display: inline-block;
        vertical-align: bottom;
        padding-top: 2px;
        font-size: 20px;
        color: color("primary", "base");
    }

    &__content {
        font-weight: 700;
        color: color("primary", "base");
    }
}
