.field {
    &__label {
        $label: &;
        display: block;
        margin: 0 0 10px;
        font-family: $font-text;
        color: color("primary", "base");
        line-height: 1.25;
        font-weight: 600;

        &.disabled {
            color: color("grey", "base");
        }

        .field--inline > & {
            margin: 0.5rem 0;
        }

        & + .field__group,
        & + .field-repeater-fields {
            margin-top: 1.2rem;

            .field--inline > & {
                margin: 0.6rem 0;
            }
        }

        sup {
            color: color("danger", "base");
        }
    }
}
